import React, { FC, useState, useEffect } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import {
    ColumnDef,
    flexRender,
    PaginationState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    FilterFn,
} from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import {
    Box,
    Grid,
    GridItem,
    Text,
    Heading,
    Input,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    ListItem,
    List,
    Spinner,
    chakra,
    Link,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Center,
} from '@chakra-ui/react'
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    DeleteIcon,
    TriangleDownIcon,
    TriangleUpIcon,
    ViewIcon,
} from '@chakra-ui/icons'

import { colors } from '@/utils/const'
import { User } from '@/interfaces'
import { Pagination } from '../utils'

interface Props {
    data: User[]
    isLoading: boolean
    handleModal: (id) => void
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export const UsersTable: FC<Props> = ({
    data = [],
    isLoading,
    handleModal,
}) => {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [mounted, setMounted] = useState(false)
    const router = useRouter()

    useEffect(() => setMounted(true), [])

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                header: () => <Text>Nombre</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'alias',
                header: () => <Text>Alias</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'email',
                header: () => <Text>Email</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'status',
                header: () => <Text>Estado</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'roles',
                header: () => <Text>ROL</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'actions',
                header: () => <Text>Acciones</Text>,
            },
        ],
        [],
    )

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    const columnFilter = table.getHeaderGroups()[0].headers[0].column

    if (!mounted) return null

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <Grid templateColumns="2fr 190px" gap={2} p={4}>
                <GridItem>
                    <Heading fontSize={18} fontWeight={600}>
                        Lista de usuarios
                    </Heading>
                </GridItem>
                <GridItem>
                    <Input
                        placeholder="Buscar"
                        size="sm"
                        rounded="md"
                        value={(columnFilter.getFilterValue() as string) ?? ''}
                        onChange={e =>
                            columnFilter.setFilterValue(e.target.value)
                        }
                    />
                </GridItem>
            </Grid>

            <TableContainer mb={2} h="360px">
                {isLoading ? (
                    <Box
                        w="full"
                        h="280px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Box>
                ) : (
                    <>
                        {table.getRowModel().rows.length === 0 ? (
                            <Box
                                h="200px"
                                w="full"
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Text fontSize={20} fontWeight={500}>
                                    No hay usuarios aún
                                </Text>
                            </Box>
                        ) : (
                            <Table variant="unstyled" size={'sm'}>
                                <Thead bg={colors['secondary-soft-hover']}>
                                    {table
                                        .getHeaderGroups()
                                        .map(headerGroup => (
                                            <Tr key={headerGroup.id}>
                                                {headerGroup.headers.map(
                                                    header => {
                                                        return (
                                                            <Th
                                                                key={header.id}
                                                                textTransform="inherit"
                                                                colSpan={
                                                                    header.colSpan
                                                                }>
                                                                {header.isPlaceholder ? null : (
                                                                    <>
                                                                        <Box
                                                                            {...{
                                                                                display:
                                                                                    'flex',
                                                                                className: header.column.getCanSort()
                                                                                    ? 'cursor-pointer select-none'
                                                                                    : '',
                                                                                onClick: header.column.getToggleSortingHandler(),
                                                                            }}>
                                                                            {flexRender(
                                                                                header
                                                                                    .column
                                                                                    .columnDef
                                                                                    .header,
                                                                                header.getContext(),
                                                                            )}
                                                                            {{
                                                                                asc: (
                                                                                    <chakra.span
                                                                                        ml={
                                                                                            1
                                                                                        }>
                                                                                        {' '}
                                                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                                                    </chakra.span>
                                                                                ),
                                                                                desc: (
                                                                                    <chakra.span
                                                                                        ml={
                                                                                            1
                                                                                        }>
                                                                                        {' '}
                                                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                                                    </chakra.span>
                                                                                ),
                                                                            }[
                                                                                header.column.getIsSorted() as string
                                                                            ] ??
                                                                                null}
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Th>
                                                        )
                                                    },
                                                )}
                                            </Tr>
                                        ))}
                                </Thead>
                                <Tbody>
                                    {table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.id} color="#474747">
                                                {row
                                                    .getVisibleCells()
                                                    .map(cell => {
                                                        if (
                                                            cell.column.id ===
                                                            'name'
                                                        ) {
                                                            return (
                                                                <Td
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    fontWeight={
                                                                        700
                                                                    }>
                                                                    <NextLink
                                                                        href={{
                                                                            pathname:
                                                                                '/users/[id]',
                                                                            query: {
                                                                                id:
                                                                                    cell
                                                                                        .row
                                                                                        .original
                                                                                        .id,
                                                                            },
                                                                        }}>
                                                                        <Link>
                                                                            {flexRender(
                                                                                cell
                                                                                    .column
                                                                                    .columnDef
                                                                                    .cell,
                                                                                cell.getContext(),
                                                                            )}
                                                                        </Link>
                                                                    </NextLink>
                                                                </Td>
                                                            )
                                                        }
                                                        if (
                                                            cell.column.id ===
                                                            'status'
                                                        ) {
                                                            return (
                                                                <Td
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                    color={
                                                                        cell.row
                                                                            .original
                                                                            .status ===
                                                                        'active'
                                                                            ? colors.tertiary
                                                                            : '#FD7C04'
                                                                    }
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    fontWeight={
                                                                        700
                                                                    }>
                                                                    {flexRender(
                                                                        cell.row
                                                                            .original
                                                                            .status ===
                                                                            'active'
                                                                            ? 'Activo'
                                                                            : 'Inactivo',
                                                                        cell.getContext(),
                                                                    )}
                                                                </Td>
                                                            )
                                                        }
                                                        if (
                                                            cell.column.id ===
                                                            'roles'
                                                        ) {
                                                            return (
                                                                <Td
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    fontWeight={
                                                                        700
                                                                    }>
                                                                    {flexRender(
                                                                        cell.row
                                                                            .original
                                                                            ?.roles[0]
                                                                            ?.description,
                                                                        cell.getContext(),
                                                                    )}
                                                                </Td>
                                                            )
                                                        }

                                                        if (
                                                            cell.column.id ===
                                                            'actions'
                                                        ) {
                                                            return (
                                                                <Td
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    p={1}>
                                                                    <Menu>
                                                                        <MenuButton aria-label="Options">
                                                                            <Box
                                                                                borderWidth={
                                                                                    1
                                                                                }
                                                                                borderRadius="md"
                                                                                cursor="pointer">
                                                                                <ChevronDownIcon
                                                                                    w={
                                                                                        5
                                                                                    }
                                                                                    h={
                                                                                        5
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </MenuButton>

                                                                        <MenuList
                                                                            minWidth="30px"
                                                                            fontSize={
                                                                                '14px'
                                                                            }>
                                                                            <MenuItem
                                                                                icon={
                                                                                    <ViewIcon />
                                                                                }
                                                                                onClick={() =>
                                                                                    router.push(
                                                                                        `/users/${cell.row.original.id}`,
                                                                                    )
                                                                                }>
                                                                                Ver
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={
                                                                                    <DeleteIcon />
                                                                                }
                                                                                onClick={() =>
                                                                                    handleModal(
                                                                                        cell
                                                                                            .row
                                                                                            .original
                                                                                            .id,
                                                                                    )
                                                                                }>
                                                                                Eliminar
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    </Menu>
                                                                </Td>
                                                            )
                                                        }
                                                        return (
                                                            <Td
                                                                key={cell.id}
                                                                fontSize={12}
                                                                fontWeight={
                                                                    500
                                                                }>
                                                                {flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext(),
                                                                )}
                                                            </Td>
                                                        )
                                                    })}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        )}
                    </>
                )}
            </TableContainer>

            {table.getPageCount() > 1 && <Pagination table={table} />}
        </Box>
    )
}
