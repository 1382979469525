import React, { FC, useState } from 'react'

import Vapor from 'laravel-vapor'
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Center,
    Progress,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
} from '@chakra-ui/react'

import { DocumentsTable } from './DocumentsTable'
import { DocumentUpload } from './DocumentUpload'
import { colors } from '@/utils/const'
import { useUser } from '@/hooks/users'
import { User } from '@/interfaces'

const headers = ['Subir documentos', 'Ver documentos']

// const type = [
//     'application/msword',
//     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ]

interface Props {
    user: User
}

export const DocumentsCard: FC<Props> = ({ user }) => {
    const { uploadFile } = useUser({ user_id: user?.id })
    const [documentSelected, setDocumentSelected] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    const toast = useToast()

    const changeDocument = e => {
        if (e.target.files && e.target.files[0] !== undefined) {
            setDocumentSelected(e.target.files[0])
        }
    }

    const handleUpload = async () => {
        setIsLoading(true)
        setProgress(0)

        Vapor.store(documentSelected, {
            progress: progress => {
                setProgress(Math.round(progress * 100))
            },
        }).then(async response => {
            const responseValues: any = {
                uuid: response.uuid,
                key: response.key,
                name: documentSelected.name,
                content_type: documentSelected.type,
            }

            try {
                await uploadFile({
                    user_id: user.id,
                    documents: [responseValues],
                })

                toast({
                    title: 'Éxito',
                    description: '¡Documento cargado con éxito!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                setDocumentSelected(null)
                setIsLoading(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                setIsLoading(false)
            }
        })
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            p={4}
            borderRadius="md"
            boxShadow="lg">
            <Tabs>
                <TabList fontSize={16} fontWeight={300}>
                    {headers.map(item => (
                        <Tab
                            key={item}
                            pb={0}
                            _selected={{
                                fontWeight: '700',
                                borderBottom: '2px',
                                borderColor: colors.secondary,
                            }}>
                            {item}
                        </Tab>
                    ))}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {documentSelected ? (
                            <>
                                <Box
                                    display="flex"
                                    h="150px"
                                    alignItems="center"
                                    justifyContent="center">
                                    {isLoading ? (
                                        <Box w="70%">
                                            <Progress
                                                hasStripe
                                                height="25px"
                                                value={progress}
                                                mb={2}
                                            />
                                            <Center>
                                                <Text
                                                    fontSize={16}
                                                    fontWeight={600}>
                                                    Subiendo documento
                                                </Text>
                                            </Center>
                                        </Box>
                                    ) : (
                                        <>
                                            <Box>
                                                <Center>
                                                    <Text
                                                        mb={6}
                                                        fontSize={16}
                                                        fontWeight={600}>
                                                        {documentSelected.name}
                                                    </Text>
                                                </Center>

                                                <Box>
                                                    <Button
                                                        bg="white"
                                                        border="1px"
                                                        borderColor={
                                                            colors.secondary
                                                        }
                                                        color={colors.secondary}
                                                        isDisabled={isLoading}
                                                        fontSize={10}
                                                        fontWeight={400}
                                                        h="28px"
                                                        mr={8}
                                                        w={110}
                                                        onClick={() =>
                                                            setDocumentSelected(
                                                                null,
                                                            )
                                                        }
                                                        _active={{
                                                            transform:
                                                                'scale(0.98)',
                                                        }}
                                                        _hover={{}}>
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        bg={colors.secondary}
                                                        border="1px"
                                                        borderColor={
                                                            colors.secondary
                                                        }
                                                        color="white"
                                                        isLoading={isLoading}
                                                        fontSize={10}
                                                        fontWeight={400}
                                                        h="28px"
                                                        w={110}
                                                        onClick={handleUpload}
                                                        _active={{
                                                            transform:
                                                                'scale(0.98)',
                                                        }}
                                                        _hover={{}}>
                                                        Guardar
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <DocumentUpload changeDocument={changeDocument} />
                        )}
                    </TabPanel>

                    <TabPanel>
                        <DocumentsTable
                            data={user?.documents}
                            isLoading={false}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}
