export function getExtensionRequestUser(profile: any) {
    const isExtensionRequest = profile?.extensionRequestList

    return {
        isExtensionRequest,
        menus: {
            bonus: profile?.bonus,
            voucherList: profile?.voucherList,
            opportunities: profile?.opportunities,
        },
    }
}
