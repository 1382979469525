import { SVGProps } from 'react'

export const WhiteSymbol = ({
    width = 50,
    height = 50,
}: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
        <path
            d="M10.418 0h29.164C45.336 0 50 4.664 50 10.418v29.164C50 45.336 45.336 50 39.582 50H10.418C4.664 50 0 45.336 0 39.582V10.418C0 4.664 4.664 0 10.418 0Zm0 0"
            style={{
                stroke: 'none',
                fillRule: 'nonzero',
                fill: '#2f59a5',
                fillOpacity: 1,
            }}
        />
        <path
            d="M18.129 38.121c-.274 0-.5-.086-.672-.262-.176-.172-.262-.398-.262-.672V12.927c0-.274.086-.5.262-.672.172-.176.398-.262.672-.262h4.926c.25 0 .46.086.636.262.2.172.297.398.297.672v19.558h11.274c.273 0 .5.102.672.301.199.172.3.399.3.672v3.73a.85.85 0 0 1-.3.672c-.172.176-.399.262-.672.262Zm0 0"
            style={{
                stroke: 'none',
                fillRule: 'nonzero',
                fill: '#fff',
                fillOpacity: 1,
            }}
        />
    </svg>
)
