import React, { FC, useState } from 'react'

import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    ModalHeader,
    Box,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'

interface Props {
    isLoading: boolean
    isOpen: boolean
    handleCloseModal: () => void
    handleDeleteUser: () => void
}

export const DeleteUserModal: FC<Props> = ({
    isLoading,
    isOpen,
    handleCloseModal,
    handleDeleteUser,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    fontSize={20}
                    fontWeight={400}
                    textAlign="center"
                    pt={6}>
                    ¿Eliminar usuario?
                </ModalHeader>
                <ModalBody>
                    <Box display="flex" justifyContent="center">
                        <Box w="75%" textAlign="center">
                            <Text
                                color="#565758"
                                fontSize={16}
                                fontWeight={400}
                                mb={4}>
                                La eliminación de un usuario lo elimina también
                                de cualquier registro.
                            </Text>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Box w="80%">
                            <Text
                                color={colors['danger-high']}
                                fontSize={12}
                                fontWeight={400}
                                mb={4}>
                                Nota: No es posible eliminar usuarios con
                                oportunidades o clientes creados.
                            </Text>
                        </Box>
                    </Box>
                </ModalBody>

                <Divider />

                <ModalFooter justifyContent="center" py={4}>
                    <Button
                        bg="white"
                        border="1px"
                        borderColor={colors.secondary}
                        color={colors.secondary}
                        fontSize={14}
                        fontWeight={400}
                        mr={8}
                        h="32px"
                        w={119}
                        onClick={handleCloseModal}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        No
                    </Button>

                    <Button
                        bg={colors.secondary}
                        border="1px"
                        color="white"
                        fontSize={14}
                        isLoading={isLoading}
                        fontWeight={400}
                        h="32px"
                        w={119}
                        onClick={handleDeleteUser}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Sí, eliminalo
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
