import React, { FC, useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Box,
    Center,
    Checkbox,
    Divider,
    Flex,
    Grid,
    GridItem,
    Input,
    InputGroup,
    Text,
    Button,
    Modal,
    ModalOverlay,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    RadioGroup,
    Radio,
    Stack,
    useToast,
} from '@chakra-ui/react'

import { UserPlus1 } from '../icons/UserPlus1'
import { colors } from '@/utils/const'
import { CloseIcon } from '@chakra-ui/icons'
import { useUsers } from '@/hooks/users'

interface Props {
    isOpen: boolean
    onClose: () => void
}

const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    email: Yup.string()
        .email('Correo inválido')
        .required('El correo es requerido'),
    alias: Yup.string(),
    roles: Yup.string().required('Debe elegir un rol'),
})

export const NewUserCard: FC<Props> = ({ isOpen, onClose }) => {
    const { getRoles, createUser } = useUsers()
    const [isLoading, setIsLoading] = useState(false)
    const [rolesAvailables, setRolesAvailables] = useState([])
    const toast = useToast()

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await getRoles()
            setRolesAvailables(roles)
        }

        rolesAvailables.length === 0 && fetchRoles()
    }, [])

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            alias: '',
            roles: '',
        },
        validationSchema: NewUserSchema,
        onSubmit: async values => {
            setIsLoading(true)
            try {
                setIsLoading(true)

                await createUser({
                    userData: {
                        name: values.name,
                        email: values.email,
                        alias: values.alias,
                        roles: [parseInt(values.roles)],
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Usuario creado con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                formik.resetForm()
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

            setIsLoading(false)
        },
    })

    const handleChangeRol = value => {
        formik.setFieldValue('roles', value)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={formik.handleSubmit}>
                    <ModalHeader px={10} py={6}>
                        <Box display="flex" justifyContent="space-between">
                            <Flex>
                                <UserPlus1 />
                                <Text ml={4} fontSize={17} fontWeight={400}>
                                    Nuevo Usuario
                                </Text>
                            </Flex>

                            <IconButton
                                fontSize={'10px'}
                                size="xs"
                                icon={<CloseIcon />}
                                onClick={onClose}
                                aria-label=""
                            />
                        </Box>
                    </ModalHeader>

                    <Divider />

                    <ModalBody px={12} py={6}>
                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.name && formik.touched.name
                            }>
                            <FormLabel fontSize={12} fontWeight={500}>
                                Nombre completo
                            </FormLabel>
                            <Input
                                id="name"
                                placeholder="Nombre completo"
                                rounded="4px"
                                size="xs"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            <FormErrorMessage>
                                {formik.errors.name}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.email && formik.touched.email
                            }>
                            <FormLabel fontSize={12} fontWeight={500}>
                                Correo electrónico
                            </FormLabel>
                            <Input
                                id="email"
                                placeholder="Correo electrónico"
                                rounded="4px"
                                size="xs"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            <FormErrorMessage>
                                {formik.errors.email}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.alias && formik.touched.alias
                            }>
                            <FormLabel fontSize={12} fontWeight={500}>
                                Alias
                            </FormLabel>
                            <Input
                                id="alias"
                                placeholder="Alias"
                                rounded="4px"
                                size="xs"
                                onChange={formik.handleChange}
                                value={formik.values.alias}
                            />
                            <FormErrorMessage>
                                {formik.errors.alias}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.roles && formik.touched.roles
                            }>
                            <FormLabel fontSize={12} fontWeight={500}>
                                Rol
                            </FormLabel>
                            <Center>
                                <RadioGroup
                                    onChange={handleChangeRol}
                                    value={formik.values.roles}>
                                    <Stack>
                                        {rolesAvailables?.map(item => {
                                            return (
                                                <Radio
                                                    key={item.id}
                                                    value={item.id.toString()}>
                                                    <Text
                                                        fontSize={12}
                                                        fontWeight={500}>
                                                        {item.description}
                                                    </Text>
                                                </Radio>
                                            )
                                        })}
                                    </Stack>
                                </RadioGroup>
                            </Center>
                            <FormErrorMessage>
                                {formik.errors.roles}
                            </FormErrorMessage>
                        </FormControl>
                    </ModalBody>

                    <Divider />

                    <ModalFooter justifyContent="center">
                        <Button
                            bg="white"
                            border="1px"
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            isDisabled={isLoading}
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            mr={8}
                            w={120}
                            onClick={() => formik.resetForm()}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Empezar de nuevo
                        </Button>
                        <Button
                            bg={colors.secondary}
                            border="1px"
                            borderColor={colors.secondary}
                            color="white"
                            isLoading={isLoading}
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            type="submit"
                            w={112}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Crear usuario
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
