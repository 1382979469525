import React, { FC, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Box,
    Button,
    Center,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    IconButton,
    Radio,
    RadioGroup,
    Spinner,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'

import { EditLine } from '../icons'
import { User } from '@/interfaces'
import { colors } from '@/utils/const'
import { useUser, useUsers } from '@/hooks/users'

interface Props {
    user: User
}

const Schema = Yup.object().shape({
    roles: Yup.string().required('Debe elegir un rol'),
})

export const UserRolsCard: FC<Props> = ({ user }) => {
    const { updateUser } = useUser({ user_id: user?.id })
    const { getRoles } = useUsers()
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rolesAvailables, setRolesAvailables] = useState([])
    const toast = useToast()

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await getRoles()
            setRolesAvailables(roles)
        }

        rolesAvailables.length === 0 && fetchRoles()
    }, [])

    const formik = useFormik({
        initialValues: {
            roles: user?.roles?.[0]?.id.toString() || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateUser({
                    user_id: user?.id,
                    data: {
                        roles: [parseInt(values.roles)],
                    },
                })

                formik.resetForm()
                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

            setIsLoading(false)
        },
    })

    const handleChangeRol = value => {
        formik.setFieldValue('roles', value)
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <form onSubmit={formik.handleSubmit}>
                <Grid w="100%" templateColumns="8fr 1fr" gap={2} p={4}>
                    <GridItem w="100%">
                        <Text mb={4} pl={2} fontSize={14} fontWeight={700}>
                            Rol
                        </Text>
                        {rolesAvailables.length === 0 ? (
                            <>
                                <Box
                                    w="full"
                                    h="120px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="lg"
                                    />
                                </Box>
                            </>
                        ) : (
                            <>
                                {isEditing ? (
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.roles &&
                                            formik.touched.roles
                                        }>
                                        <Center>
                                            <RadioGroup
                                                display="flex"
                                                justifyContent="wrap"
                                                onChange={handleChangeRol}
                                                isDisabled={!isEditing}
                                                value={formik.values.roles}>
                                                {rolesAvailables?.map(item => {
                                                    return (
                                                        <Radio
                                                            mx={2}
                                                            key={item.id}
                                                            value={item.id.toString()}>
                                                            <Text
                                                                fontSize={12}
                                                                fontWeight={
                                                                    500
                                                                }>
                                                                {
                                                                    item.description
                                                                }
                                                            </Text>
                                                        </Radio>
                                                    )
                                                })}
                                            </RadioGroup>
                                        </Center>
                                        <FormErrorMessage>
                                            {formik.errors.roles}
                                        </FormErrorMessage>

                                        <Center>
                                            <Button
                                                bg={colors.secondary}
                                                border="1px"
                                                color="white"
                                                fontSize={12}
                                                fontWeight={500}
                                                mt={4}
                                                h="24px"
                                                isLoading={isLoading}
                                                type="submit"
                                                w={84}
                                                _active={{
                                                    transform: 'scale(0.98)',
                                                }}
                                                _hover={{}}>
                                                Guardar
                                            </Button>
                                        </Center>
                                    </FormControl>
                                ) : (
                                    <>
                                        <Center>
                                            <RadioGroup
                                                display="flex"
                                                justifyContent="wrap"
                                                isDisabled
                                                value={user?.roles?.[0]?.id.toString()}>
                                                {rolesAvailables?.map(item => {
                                                    return (
                                                        <Radio
                                                            mx={2}
                                                            key={item.id}
                                                            value={item.id.toString()}>
                                                            <Text
                                                                fontSize={12}
                                                                fontWeight={
                                                                    500
                                                                }>
                                                                {
                                                                    item.description
                                                                }
                                                            </Text>
                                                        </Radio>
                                                    )
                                                })}
                                            </RadioGroup>
                                        </Center>
                                    </>
                                )}
                            </>
                        )}
                    </GridItem>
                    <GridItem w="100%" gridColumn={'end'}>
                        <IconButton
                            aria-label={''}
                            bg="transparent"
                            size="xs"
                            icon={<EditLine color={colors['black-soft']} />}
                            onClick={() => {
                                formik.resetForm()
                                setIsEditing(!isEditing)
                            }}
                            _active={{}}
                            _hover={{}}
                        />
                    </GridItem>
                </Grid>
            </form>
        </Box>
    )
}
