import { FC } from 'react'
import Head from 'next/head'

import { Navbar } from '@/components/navbar/Navbar'

interface Props {
    children: JSX.Element | JSX.Element[]
    title?: string
}

const AppLayout: FC<Props> = ({ children, title }) => {
    return (
        <div>
            <Head>
                <title>Kit - Digital {title ? `- ${title}` : ''}</title>
            </Head>
            <div className="min-h-screen bg-white">
                <Navbar />

                {/* Page Content */}
                <main className="w-full">{children}</main>
            </div>
        </div>
    )
}

export default AppLayout
