import React, { ChangeEventHandler, useState } from 'react'

import { Box, Container, Heading, Input, Link, Stack } from '@chakra-ui/react'

type Props = {
    changeDocument: ChangeEventHandler<HTMLInputElement>
    dimensionsHeight?: string
    multiple?: boolean
    documentType?: string
} & React.ComponentPropsWithRef<typeof Container>

export const DocumentUpload = ({
    changeDocument,
    dimensionsHeight,
    multiple = true,
    documentType = '',
    ...delegated
}: Props) => {
    return (
        <Container {...delegated} my={4}>
            <Box w="100%" bg="gray.200" _hover={{ bg: 'gray.300' }}>
                <Box
                    position="relative"
                    height={dimensionsHeight ? dimensionsHeight : 90}
                    width="100%">
                    <Stack
                        alignItems="center"
                        display="flex"
                        height="100%"
                        justifyContent="center"
                        textAlign="center"
                        width="100%">
                        <Heading fontSize={16} fontWeight={400}>
                            Arrastra tus archivos o <Link>examinar</Link>
                        </Heading>
                    </Stack>
                    <Input
                        aria-hidden="true"
                        height="100%"
                        left="0"
                        cursor="pointer"
                        onChange={changeDocument}
                        multiple={multiple}
                        opacity="0"
                        position="absolute"
                        top="0"
                        type="file"
                        width="100%"
                        accept={documentType}
                    />
                </Box>
            </Box>
        </Container>
    )
}
