import React, { FC, useState, useEffect, useRef } from 'react'

import {
    ColumnDef,
    flexRender,
    PaginationState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    FilterFn,
} from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import {
    Box,
    Text,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    ListItem,
    List,
    Spinner,
    chakra,
    IconButton,
} from '@chakra-ui/react'
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    TriangleDownIcon,
    TriangleUpIcon,
} from '@chakra-ui/icons'

import { colors } from '@/utils/const'
import { EyeLine, Download2 } from '../icons'
import { useUsers } from '@/hooks/users'
import { Pagination } from '../utils'

interface Props {
    data: any[]
    isLoading: boolean
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export const DocumentsTable: FC<Props> = ({ data = [], isLoading }) => {
    const { downloadFile, viewFile } = useUsers()
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [mounted, setMounted] = useState(false)

    useEffect(() => setMounted(true), [])

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                header: () => <Text>Nombre</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'actions',
                header: () => <Text>Acciones</Text>,
            },
        ],
        [],
    )

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    if (!mounted) return null

    const handleDownload = async file_id => {
        await downloadFile({ file_id })
    }

    const handleView = async file_id => {
        const download = await viewFile({ file_id })
        const link = document.createElement('a')
        link.href = download
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <TableContainer mb={2} minH="150px">
                {isLoading ? (
                    <Box
                        w="full"
                        h="280px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Box>
                ) : (
                    <>
                        {table.getRowModel().rows.length === 0 ? (
                            <Box
                                h="150px"
                                w="full"
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Text fontSize={20} fontWeight={500}>
                                    No hay documentos aún
                                </Text>
                            </Box>
                        ) : (
                            <Table variant="unstyled" size={'sm'}>
                                <Thead bg={colors['secondary-soft-hover']}>
                                    {table
                                        .getHeaderGroups()
                                        .map(headerGroup => (
                                            <Tr key={headerGroup.id}>
                                                {headerGroup.headers.map(
                                                    header => {
                                                        return (
                                                            <Th
                                                                key={header.id}
                                                                textTransform="inherit">
                                                                {header.isPlaceholder ? null : (
                                                                    <>
                                                                        {header.id ===
                                                                        'actions' ? (
                                                                            <Box w="80px">
                                                                                {flexRender(
                                                                                    header
                                                                                        .column
                                                                                        .columnDef
                                                                                        .header,
                                                                                    header.getContext(),
                                                                                )}
                                                                            </Box>
                                                                        ) : (
                                                                            <Box
                                                                                {...{
                                                                                    display:
                                                                                        'flex',
                                                                                    className: header.column.getCanSort()
                                                                                        ? 'cursor-pointer select-none'
                                                                                        : '',
                                                                                    onClick: header.column.getToggleSortingHandler(),
                                                                                }}>
                                                                                {flexRender(
                                                                                    header
                                                                                        .column
                                                                                        .columnDef
                                                                                        .header,
                                                                                    header.getContext(),
                                                                                )}
                                                                                {{
                                                                                    asc: (
                                                                                        <chakra.span
                                                                                            ml={
                                                                                                1
                                                                                            }>
                                                                                            {' '}
                                                                                            <TriangleUpIcon aria-label="sorted ascending" />
                                                                                        </chakra.span>
                                                                                    ),
                                                                                    desc: (
                                                                                        <chakra.span
                                                                                            ml={
                                                                                                1
                                                                                            }>
                                                                                            {' '}
                                                                                            <TriangleDownIcon aria-label="sorted descending" />
                                                                                        </chakra.span>
                                                                                    ),
                                                                                }[
                                                                                    header.column.getIsSorted() as string
                                                                                ] ??
                                                                                    null}
                                                                            </Box>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Th>
                                                        )
                                                    },
                                                )}
                                            </Tr>
                                        ))}
                                </Thead>
                                <Tbody>
                                    {table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.id} color="#474747">
                                                {row
                                                    .getVisibleCells()
                                                    .map(cell => {
                                                        if (
                                                            cell.column.id ===
                                                            'actions'
                                                        ) {
                                                            return (
                                                                <Td
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                    w="80px"
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    px={2}>
                                                                    <EyeLine
                                                                        cursor="pointer"
                                                                        transform="scale(0.85)"
                                                                        color={
                                                                            colors[
                                                                                'gray-primary'
                                                                            ]
                                                                        }
                                                                        onClick={() =>
                                                                            handleView(
                                                                                cell
                                                                                    .row
                                                                                    .original
                                                                                    .id,
                                                                            )
                                                                        }
                                                                    />
                                                                    <Download2
                                                                        cursor="pointer"
                                                                        transform="scale(0.85)"
                                                                        color={
                                                                            colors[
                                                                                'gray-primary'
                                                                            ]
                                                                        }
                                                                        onClick={() =>
                                                                            handleDownload(
                                                                                cell
                                                                                    .row
                                                                                    .original
                                                                                    .id,
                                                                            )
                                                                        }
                                                                    />
                                                                </Td>
                                                            )
                                                        }
                                                        return (
                                                            <Td
                                                                key={cell.id}
                                                                w="full"
                                                                maxW="530px"
                                                                fontSize={12}
                                                                fontWeight={
                                                                    500
                                                                }>
                                                                <Box overflow="hidden">
                                                                    {flexRender(
                                                                        cell
                                                                            .column
                                                                            .columnDef
                                                                            .cell,
                                                                        cell.getContext(),
                                                                    )}
                                                                </Box>
                                                            </Td>
                                                        )
                                                    })}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        )}
                    </>
                )}
            </TableContainer>

            {table.getPageCount() > 1 && <Pagination table={table} />}
        </Box>
    )
}
